import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';


import '@fortawesome/fontawesome-free/css/all.css' 

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa',
      },
    treeShake: true,
    theme: {
        dark: false,
        options: {
            customProperties: true
        },
        themes: {
            dark: {

            },
            light: {
                background: '#edf6f9'
            }
        }
    }
});
