<template>
  <div id="app">
    <v-app>
<!-- 
    <v-app-bar
      app
      color="white"
      flat
    >

      <v-tabs
        centered
        class="ml-n9"
        color="grey darken-1"
      >
        <v-tab 
          v-for="link in links"
          :key="link.text"
          :to="link.href"
        >
          {{ link.text }}
        </v-tab>
      </v-tabs>


    </v-app-bar> -->



    <v-main>
      <router-view/>
    </v-main>
    </v-app>
  </div>
</template>

<style lang="scss">


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script>
  export default {
    name: 'App',
    data: () => ({
      links: [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: 'About',
          href: '/about',
        }
      ],
    }),
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Magical Talking Ferret',
      // all titles will be injected into this template
      titleTemplate: 'Ziggbee - %s',
      meta: [
        { name: 'description', content: 'Looking for some magic to improve your day? Then look no further! Ziggbee is here for a family friendly casual variety stream! Oh, and did I mention that he\'s a talking ferret?'},
        { name: 'robots', content: 'index,follow'},
        { charset: 'utf-8' },
      ]
    }
  }
</script>